@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap');

body {
    color: white;
    font-family: 'Yanone Kaffeesatz', sans-serif;
}

.App {
    background-color: #000;
    height: fit-content;
    min-height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App a {
    color: #fff;
}

.rotateScreen img{
    filter: invert(100%);
    z-index: 999;
    height: 80vh;
    width: auto;
    display: none;
}


