.About-container {
    border: solid #fff 4px;
    height: 80vh;
    box-shadow: 0 0 20px 0 #fff;
    display: flex;
    z-index: 2;
    position: relative;
    justify-content: center;
    gap: 50px;
    align-items: center;
    width: 80vw;
    font-size: 3.5vh;
}

.about-wrapper {
    position: absolute;
    display: flex;
    bottom: 0;
    height: 82%;
    width: 100%;
    overflow: hidden;
    justify-content: space-around;
    padding: 1vh 0;
}

.about-intro-title {
    margin: 2vh;
}

.about-intro-desc {
    font-size: 0.9em;
    margin-bottom: 4vh;
}

.about-intro-container {
    width: 40%;
    text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black;
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
}

.about-map-img {
    margin: auto 0;
    opacity: 0.7;
    position: absolute;
    z-index: -10;
    width: 100%;
    border-radius: 20px;
}

.about-tech-container {
    width: 40%;
    display: flex;
    font-size: 0.9em;
    height: 100%;
    text-shadow: 0 0 5px #000, 3px -2px 4px #000;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
}

.about-tech-list {
    margin-bottom: 8vh;
    padding: 0 0 0 15%;
}

.about-tech-title {
    text-align: center;
    padding-bottom: 5vh;
}

.about-portrait-img {
    width: 100%;
    position: absolute;
    z-index: -2;
}

.about-tech-container:hover .about-portrait-img {
    z-index: 50;
}

.about-intro-container:hover .about-map-img {
    z-index: 50;
    opacity: 1;
}

/* responsive design changes */


@media screen and (max-width: 1200px) {

    .about-portrait-img,
    .about-map-img {
        display: none;
    }

    .about-intro-container,
    .about-tech-container {
        justify-content: center;
        width: 45%;
        font-size: 0.9em;
    }

    .about-tech-title {
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {

    .About-container {
        font-size: 3vw;
    }
}

@media screen and (max-width: 500px) {

    .About-container {
        font-size: 4vw;
        display: flex;

    }

    .about-wrapper {
        height: 86%;
        flex-direction: column;
        align-items: center
    }

    .about-intro-container,
    .about-tech-container {
        width: 90%;
        align-items: center;
        height: auto;
    }

    .about-tech-list {
        margin: 0;
    }

}