.contact-container {
    border: solid #fff 4px;
    height: 80vh;
    box-shadow: 0 0 20px 0 #fff;
    display: flex;
    z-index: 2;
    position: relative;
    justify-content: center;
    gap: 50px;
    align-items: center;
    width: 80vw;
}

.contact-container h3 {
    color: white;
    font-size: 5vh;
    font-weight: 700;
    text-align: center;
}

.contact-intro {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 30vw;
}

.contact-divider {
    height: 50vh;
    width: 3px;
    margin-top: 88px;
    background-color: wheat;
}

.form-container {
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    align-content: center;
    align-items: center;
}

.contact-container input, textarea {
    width: 30vw;
    border-radius: 10px;
    font-size: 2.5vh;
    padding: 20px;
    overflow-y: hidden;
    color: #000;
    font-family: 'Yanone Kaffeesatz', sans-serif;
}

.contact-container select {
    width: 32vw;
    border-radius: 10px;
    font-size: 2.5vh;
    padding: 20px;
    overflow-y: hidden;
    color: #000;
    font-family: 'Yanone Kaffeesatz', sans-serif;
}

.contact-container p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.contact-container label {
    font-size: 2.5vh;
}

#button-section {
    flex-direction: row;
    justify-content: center;
    gap: 10%;
}

.contact-container button {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 2.5vh;
    padding: 1.5%;
    width: 10vw;
    border-radius: 10px;
    background-color: white;

}

.contact-container select:-internal-list-box option:checked {
    padding: 5px;
    background-color: blue;

}

.contact-container option {
    text-align: center;
}



/* responsive design changes */

@media screen and (max-width: 1300px) {

    .contact-container {
        flex-direction: column;
        gap: 0;
        justify-content: flex-end;
    }

    .contact-intro {
        padding: 0;
        flex-direction: row;
        width: 80vw;
        justify-content: center;
    }

    .contact-divider {
        display: none;
    }

    .form-container {
        padding: 0;
    }

    .contact-container input,
    select,
    textarea {
        width: 45vw;
    }

    .contact-container select {
        width: 49vw;
    }

    .contact-container button {
        width: 15vw;
    }

}

@media screen and (max-width: 1000px) {
    .contact-container h3 {
        font-size: 4vh;
    }
}

@media screen and (max-width: 500px) {


    .contact-intro {
        flex-direction: column;
        gap: 0;
        padding-bottom: 5%;
    }

    .contact-intro h3 {
        font-size: 6vw;
    }

    .contact-container input,
    select,
    textarea {

        width: 50vw;
    }

    .contact-container select {
        width: 60vw;
    }

    .contact-container button {
        width: 15vw;
    }

    .contact-container button {
        padding: 2.5%;
        width: 30vw;
    }
}

@media screen and (max-width: 500px) and (max-height: 880px) {

    .contact-intro {
        padding: 0;
    }

    .form-container  input, select, textarea{
        font-size: 2vh;
        padding: 10px;
    }
    
    .contact-container select {
        font-size: 2vh;
        padding: 10px;
        width: 56vw;
    }

    .contact-container button {
        font-size: 2vh;
    }
}