.Landing-container {
    border: solid #fff 4px;
    height: 80vh;
    box-shadow: 0 0 20px 0 #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: containerShape 6s ease-out;
    width: 80vw;
    text-align: center;
}

@keyframes startUp {
    from {font-size: 1vw;}
    to {font-size: 18vw;}
}

@keyframes containerShape {
    from {
        border-radius: 50%;
        height: 0vh;
        width: 0vw;
    }
    to {
        border-radius: 0%;
        height: 80vh;
        width: 80vw;
    }
}

.Landing-container h1 {
    border: none;
    animation: startUp 6s ease-out ;
    font-size: 18vw;
    text-align: center;
}



/* responsive design changes */

@media screen and (max-width: 1400px)  {
    
    
}